<template lang="pug">
	.widget-container
		el-card.box-card.mg.a1
			template(slot="header")
				//h2.no-line-height ZGPM Notifier
				el-image(:src="logo")
			el-form.mg.a1(ref="form" :model="form" :rules="rules" layout="vertical")
				el-row(:gutter="24")
					el-col(:span="24")
						el-form-item(has-feedback="" ref="itemUsername" prop="username")
							el-input(placeholder="Username" v-model="form.username")
					el-col(:span="24")
						el-form-item(has-feedback="" ref="itemPass" prop="password")
							el-input(placeholder="Password " type="password" :show-password="true" v-model="form.password")
					el-col(:span="24")

						el-button.w-100(type="primary" html-type="submit" @click.prevent.native="handleSubmit") Login
</template>

<style lang="scss" scoped>
		.box-card {
			width: 500px;
			margin-left: auto;
			margin-right: auto;

			@media only screen and (max-width: 560px) {
				width: 95%;
			}
		}
</style>

<script lang="js">
import {validateEmail, validatePass, validateAlphaNumeric} from "@/utils/validate";
import {Login} from "@/api/auth";

	export default  {
		name: 'login',
		data() {
			return {
				form: {
					username: '',
					password: ''
				},
				rules: {
					//email: { required: true, validator: validateEmail, trigger: 'blur' },
					username: { required: true, validator: validateAlphaNumeric, trigger: 'blur' },
					password: { required: true, validator: validatePass, trigger: 'blur' }
				}
			}
		},
		computed: {
			logo() {
				return require('../../assets/' + process.env.VUE_APP_LOGO)
			}
		},
		methods: {
			handleSubmit() {
				this.$refs.form.validate(async valid => {
					if(!valid) return this.$message.error('Login fields are invalid');
					this.$store.dispatch('user/login', this.form)
							.then(() => window.location.href = '/dashboard')
				})
			},

		}
	}
</script>
